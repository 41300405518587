import {
  Swiper,
  Autoplay,
  Scrollbar,
  Navigation,
} from "swiper/swiper-bundle.esm.js";

import "swiper/scss";
import "swiper/scss/scrollbar";
import "swiper/scss/navigation";
import "swiper/scss/autoplay";

jQuery(($) => {
  var ppp = 12; // Post per page
  var pageNumber = 2;
  var term = "";

  $(".packages-cities").on("change", "select", function () {
    var selectedMenu = $(this);

    const text_button = $(".info.birdie .info-inner .links a").text();
    console.log(text_button);
    const custom_button = $(".custom .info-inner a.btn").text();

    const custom_price = $(".custom .info-content.price").text();
    const custom_group_size = $(".custom .info-content.group")
      .text()
      .replace("Group Size", "");
    const custom_details = $(".custom .info-content.details")
      .text()
      .replace("Details", "");
    const custom_link = $(".custom .info-inner a.btn").attr("href");
    const bar_options = $(".swiper-packages .other-options").html();

    if (selectedMenu.val() != "City") {
      $.ajax({
        type: "POST",
        dataType: "html",
        url: ajax_posts.ajaxurl,
        data: {
          id: selectedMenu.val(),
          //class: $page_id,
          text_button: text_button,
          custom_button: custom_button,
          custom_price: custom_price,
          custom_group_size: custom_group_size,
          custom_details: custom_details,
          custom_link: custom_link,
          bar_options: bar_options,
          action: "location_packages_ajax",
        },
        beforeSend: function (jqXHR) {
          $(".package-container-row").addClass("loading");
        },
        success: function (data) {
          if (data) {
            $(".package-container-row").html(data);

            $(".par .info-content.price span.h3").counter(0);
            $(".birdie .info-content.price span.h3").counter(0);
            $(".eagle .info-content.price span.h3").counter(0);

            //take 3 seconds to load the content
            setTimeout(function () {
              $(".package-container-row").removeClass("loading");
            }, 1000);

            new Swiper(".swiper-packages", {
              loop: false,
              longSwipes: false,
              initialSlide: 0,
              centeredSlides: true,
              spaceBetween: 0,
              slidesPerView: 3,
              scrollbar: {
                el: ".swiper-scrollbar",
                draggable: true,
                hide: false,
              },
            });

            $(window).on("resize load", () => {
              if (window.innerWidth > 1023) {
                $(".swiper-packages .swiper-wrapper").addClass("disabled");
              } else {
                $(".swiper-packages .swiper-wrapper").removeClass("disabled");
              }
            });
          }
        },
        afterSend: function (jqXHR) {
          console.log(jqXHR);
        },
        error: function (jqXHR, textStatus, errorThrown) {
          console.log(jqXHR + " :: " + textStatus + " :: " + errorThrown);
        },
      });
    }
  });

  $(".menu-city-changer").on("change", "select", function () {
    var selectedMenu = $(this);
    if (selectedMenu.val() != "City") {
      $.ajax({
        method: "POST",
        dataType: "html",
        url: ajax_posts.ajaxurl,
        data: {
          id: selectedMenu.val(),
          action: "location_menu_ajax",
        },
        beforeSend: function (jqXHR) {
          $(".menu-content.menu-row").html("Loading");
          $(".menu-tabs-first").hide();
          $.ajax({
            method: "POST",
            dataType: "html",
            url: ajax_posts.ajaxurl,
            data: {
              id: selectedMenu.val(),
              action: "city_name_ajax",
            },
            beforeSend: function (jqXHR) {},
            success: function (data) {
              if (data) {
                $("span.sub-title").html(data);
              }
            },
            afterSend: function (jqXHR) {
              console.log(jqXHR);
            },
            error: function (jqXHR, textStatus, errorThrown) {
              console.log(jqXHR + " :: " + textStatus + " :: " + errorThrown);
            },
          });
        },
        success: function (data) {
          if (data) {
            $(".menu-tabs .nav-tabs li").removeClass("active");
            $(".menu-tabs .nav-tabs li:first").addClass("active");
            $(".menu-container-row").html(data);

            $(".menu-tabs").on("click", "li", function () {
              const classList = $(this).attr("class").split(" ");
              if (classList[0]) {
                $(".menu-tabs .nav-tabs li").removeClass("active");
                $(".menu-section").removeClass("active");

                $(`.menu-section.${classList[0]}`).addClass("active");
                $(`.menu-tabs .nav-tabs li.${classList[0]}`).addClass("active");
              }
            });

            // $("#menu-content .menu-row").html(data);
          } else {
            $(".menu-container-row").html("No Items");
          }
        },
        afterSend: function (jqXHR) {
          console.log(jqXHR);
        },
        error: function (jqXHR, textStatus, errorThrown) {
          console.log(jqXHR + " :: " + textStatus + " :: " + errorThrown);
        },
      });
    }
  });

  $(".filters.cate").on("click", function () {
    var button = $(this);

    $(".filters.cate").removeClass("active");
    $(this).addClass("active");

    var classList = button.attr("class").split(" ");
    var theType = classList[2].split("-");
    var term = theType[1];

    pageNumber = 1;

    var str =
      "&pageNumber=" +
      "1" +
      "&ppp=" +
      ppp +
      "&term=" +
      term +
      "&type=post&action=more_post_ajax";

    $.ajax({
      type: "POST",
      dataType: "html",
      url: ajax_posts.ajaxurl,
      data: str,
      beforeSend: function (jqXHR) {},
      success: function (data) {
        if ($(".more-posts.read--more").length > 0) {
          var buttonClassList = $(".more-posts.read--more")
            .attr("class")
            .split(" ");

          $(".more-posts.read--more").text("Load More");
          $(".more-posts.read--more").show();

          if (buttonClassList.length > 4) {
            var lastClass = $(".more-posts.read--more")
              .attr("class")
              .split(" ")
              .pop();
            $(".more-posts.read--more").removeClass(lastClass);
          }

          $(".more-posts.read--more").addClass("more-" + term);
        }

        if (data) {
          $(".row.news--content").html(data);
        } else {
          console.log("No More Items");
          $(".row.news--content").html("");
        }
      },
      afterSend: function (jqXHR) {},
      error: function (jqXHR, textStatus, errorThrown) {
        $loader.html(jqXHR + " :: " + textStatus + " :: " + errorThrown);
      },
    });
    pageNumber++;
  });

  $(".more-posts.read--more").on("click", function () {
    var button = $(this);
    var btnText = button.text();
    button.text("Loading...");

    var classList = button.attr("class").split(" ");
    if (classList[4] && classList[4] != "all") {
      var theType = classList[4].split("-");
      var term = theType[1];
    } else {
      var term = "";
    }

    var str =
      "&pageNumber=" +
      pageNumber +
      "&ppp=" +
      ppp +
      "&term=" +
      term +
      "&type=post&action=more_post_ajax";

    $.ajax({
      type: "POST",
      dataType: "html",
      url: ajax_posts.ajaxurl,
      data: str,
      beforeSend: function (jqXHR) {
        button.text("Loading...");
      },
      success: function (data) {
        if (data) {
          $(".row.news--content").append(data);
          button.text(btnText);
        } else {
          button.hide();
          /* $(".row.news--content").text(
            "No more posts"
          ); */
          console.log("No More Items");
        }
      },
      afterSend: function (jqXHR) {
        button.text("More posts").prev().before(data);
      },
      error: function (jqXHR, textStatus, errorThrown) {
        $loader.html(jqXHR + " :: " + textStatus + " :: " + errorThrown);
      },
    });
    pageNumber++;
  });
});
